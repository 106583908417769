<script>
  import PaintbrushDivider from "./../components/PaintbrushDivider.svelte";

  let ongoingProjects = [
    "Natural Grocers",
    "Xfinity (2)",
    "Denver Premium Outlets (Retail Interior Fit-Out)",
    "7 For All Manking (Retail Store)"
  ];

  let completedProjects = [
    "Banter Barbershop",
    "Van Heusen Retail store",
    "Xfinity Retail Store",
    "Parachute Facility"
  ];
</script>

<style>
  .list-group-item {
    border: 0px;
  }

  .jumbotron {
    background-color: #49494b;
    color: lightgray;
    border-radius: 0px;
  }

  .red-text {
    font-weight: bold;
    /* font-size: 1.05em; */
    /* color: #bf1e2e; */
  }

  /* .paintbrush-container:after {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #fff;
    transition: width 0.2s ease;
    -webkit-transition: width 0.2s ease;
  }

  .paintbrush-container:hover:after {
    width: 100%;
    left: 0;
  } */
</style>

<div class="jumbotron">
  <div class="container">
    <h1 class="display-4">Who We Are</h1>
    <p class="lead">
      Construction Painting Professionals, LLC is Denver's premier commercial
      and construction painting solution.
    </p>

    <PaintbrushDivider />

    <!-- <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->
    <!-- <hr class="my-4" /> -->
    <p>
      We are
      <span class="red-text">deadline-driven,</span>
      <span class="red-text">budget-aware</span>
      and
      <span class="red-text">communicative</span>
      from beginning to end. We have the tools, knowledge, financial backing and
      experience necessary to handle any commercial project no matter the needs.
    </p>
  </div>
</div>

<div class="jumbotron row">

  <div class="col-md-4">
    <h2>Completed Projects</h2>
    <ul class="list-group">
      {#each completedProjects as project}
        <li class="list-group-item text-center">{project}</li>
      {/each}
    </ul>
  </div>

  <div class="col-md-4">
    <h2>What Sets Us Apart</h2>
    <p>
      We strive to be the top choice for general contractors. Our company is
      built on ideals and practices that set a cut above the rest. We pride
      ourselves in our ability to...
    </p>
    <!-- <hr class="my-4" /> -->
    <!-- <ul>
      <li>Do what is necessary to meet any and all deadlines</li>
      <li>Find all details in drawings that relate to our work</li>
      <li>
        Offer an open phone policy so all Super-intendents, Project managers and
        other trades may reach us at any time to ensure emergencies or issues
        can and will be resolved in a timely manner
      </li>
      <li>
        Have the proper tools, safety equipment and manpower to do a job right,
        the first time.
      </li>
    </ul> -->
  </div>

  <!-- <div class="col-md-4">
    <h3>Ongoing Projects</h3>
    <hr class="my-4" />
    <ul class="">
      <li>Natural Grocers</li>
      <li>Xfinity (2)</li>
      <li>Denver Premium Outlets retail interior fit-out</li>
      <li>7 For All Mankind retail store</li>
    </ul>
  </div> -->

  <div class="col-md-4">
    <h2>Ongoing Projects</h2>
    <ul class="list-group">
      {#each ongoingProjects as project}
        <li class="list-group-item text-center">{project}</li>
      {/each}
    </ul>
  </div>

</div>

<!-- <p>
    We were formed in 2018 by owners Thomas LoRusso and Connor Devine. Co-owner
    Thomas was the previous owner of the still operating 26 year old company
    LoRusso Painting. Thomas and Connor decided to form CPP and relocate to
    Denver bringing with them the knowledge, experience and dedication that was
    instilled in them while rising through the ranks in Jacksonville, Florida.
  </p> -->
