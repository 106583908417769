<script>
  import Navbar from "./components/Navbar.svelte";
  import Home from "./routes/Home.svelte";
  import About from "./routes/About.svelte";
  import Gallery from "./routes/Gallery.svelte";
  import TestRoute from "./routes/TestRoute.svelte";
  import Contact from "./routes/Contact.svelte";
  // import NotFound from "./routes/NotFound.svelte";

  import Router from "svelte-spa-router";

  const routes = {
    "/": Home,
    "/about": About,
    "/gallery": Gallery,
    "/test": TestRoute,
    "/contact": Contact
    // Catch-all
    // This is optional, but if present it must be the last
    // "*": NotFound
  };
</script>

<Navbar />

<Router {routes} />
