<script>
  let services = [
    "Interior / Exterior Painting",
    "Intumescent Coating",
    "Concrete Stain / Sealing",
    "Wood Stain / Sealing",
    "Epoxy / Solvent Based Applications",
    "Specialty Coatings and More!"
  ];
</script>

<style>
  .info-block {
    padding-top: 1rem;
    background-color: #bfbdc1;
  }

  .info-block h2 {
    text-align: center;
  }
</style>

<div class="col-md-6 info-block" style="background-color: #BFBDC1">
  <h2>Services Offered</h2>
  <br />
  <div class="row">
    {#each services as service}
      <div class="col-md-6 d-block p-2 text-center">{service}</div>
    {/each}
  </div>
</div>
