<script>
  import MyCarousel from "./../components/MyCarousel.svelte";

  let images = [
    // Commercial
    { src: "https://i.ibb.co/VtDJ8cL/IMG-4114.jpg" },
    { src: "https://i.ibb.co/b3rZSWT/IMG-4111.jpg" },
    { src: "https://i.ibb.co/VC4qz8Y/file1.jpg" },
    { src: "https://i.ibb.co/4PX2hb8/file.jpg" },
    { src: "https://i.ibb.co/d6dYbjy/IMG-4126.jpg" },
    { src: "https://i.ibb.co/ZBvLQbv/IMG-20200110-175104-470.jpg" },
    { src: "https://i.ibb.co/WDdHFZN/20200108-131713.jpg" },
    { src: "https://i.ibb.co/0qFTqtd/20200104-164353.jpg" },
    { src: "https://i.ibb.co/0CzkZ5D/20200104-130000.jpg" },
    { src: "https://i.ibb.co/Xygdr9Q/20200104-105548.jpg" },
    { src: "https://i.ibb.co/Cm8sFfj/20200102-032900.jpg" },
    { src: "https://i.ibb.co/jw4W7Vv/20200102-031811.jpg" },
    { src: "https://i.ibb.co/gPTGbfG/20191115-205319.jpg" },
    { src: "https://i.ibb.co/jH9DVdY/20191115-205105.jpg" },
    { src: "https://i.ibb.co/pK44vYh/20191115-205003.jpg" },
    { src: "https://i.ibb.co/M8s2PNP/20191115-204840.jpg" },
    { src: "https://i.ibb.co/6HmnJYn/20191115-204551.jpg" },
    // Multi-Family
    { src: "https://i.ibb.co/ZBWsxCQ/Summer-Key-Rendering.jpg" },
    { src: "https://i.ibb.co/RyCC5ph/ISj7lcr7h6vnd60000000000.png" },
    { src: "https://i.ibb.co/mzB7BfT/ISbdg2ynyypfuj1000000000.jpg" },
    { src: "https://i.ibb.co/xg8pkNz/Annotation-2020-01-05-185502aa.png" },
    { src: "https://i.ibb.co/dDhZ4s7/IS7eq7ku3s60xc0000000000.png" },
    // Residential
    { src: "https://i.ibb.co/B6P6zz0/20180602-135103.jpg" },
    { src: "https://i.ibb.co/0YfkKRF/20190218-143449.jpg" },
    { src: "https://i.ibb.co/mBzhd05/20190403-170346.jpg" },
    { src: "https://i.ibb.co/qsBLnwK/20190403-170408.jpg" },
    { src: "https://i.ibb.co/2Mq0w5z/20190403-170441.jpg" },
    { src: "https://i.ibb.co/544mJZX/1423690793233.jpg" },
    { src: "https://i.ibb.co/ynCJwk7/1432049494663-1.jpg" },
    { src: "https://i.ibb.co/xFB3QJ3/DSCF0787.jpg" },
    { src: "https://i.ibb.co/hYLGtz2/Fireplace.png" },
    { src: "https://i.ibb.co/C6Vvphc/Fireplace2.png" },
    { src: "https://i.ibb.co/nfxpbpf/hilltop-1.jpg" },
    { src: "https://i.ibb.co/qRgPPn3/Kitchen-3.png" },
    { src: "https://i.ibb.co/tZ3Hcck/Photo-Grid-1457809779240-1.jpg" },
    { src: "https://i.ibb.co/jVLkW8t/Upstairs-Bath.png" },
    { src: "https://i.ibb.co/F76ttgq/150918-150041-COLLAGE-1-1.jpg" }
  ];

  const setSelectedImage = img => (selectedImageUrl = img);
</script>

<style>
  .my-gallery-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  a {
    padding: 1rem;
  }

  .my-gallery-images a img {
    max-height: 40vh;
    max-width: 40vw;
    border-radius: 0.2rem;
    border: 0.1rem solid darkgray;
  }

  .my-timelapse {
    margin: 1rem;
    border-radius: 0.2rem;
    border: 0.1rem solid darkgray;
  }
</style>

<div class="container bg-dark">
  <div class="row my-timelapse">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe
        class="embed-responsive-item"
        title="CPP Timelapse"
        src="https://www.youtube.com/embed/g5TlfxKi69o?&autoplay=1"
        allowfullscreen />
    </div>
  </div>
</div>
<div class="my-gallery-images">
  {#each images as { src }}
    <a href={src} target="_blank">
      <img {src} alt="" class="img-fluid" />
    </a>
  {/each}
</div>
