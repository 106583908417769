<script>
  let pictures = [
    "https://i.ibb.co/WDdHFZN/20200108-131713.jpg",
    "https://i.ibb.co/d6dYbjy/IMG-4126.jpg",
    "https://i.ibb.co/nfxpbpf/hilltop-1.jpg"
  ];

  let activePicture = pictures[0];

  function cycle(i) {
    activePicture = pictures[(i + 1) % pictures.length];
  }
</script>

<style>
  .container {
    height: 100vh;
  }

  .carousel-item {
    max-height: 500px;
  }

  .carousel-control-prev {
    cursor: pointer;
  }

  .carousel-control-next {
    cursor: pointer;
  }
</style>

<div class="container">
  <div class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      {#each pictures as picture}
        <div class="carousel-item" class:active={picture == activePicture}>
          <img class="d-block w-100" src={activePicture} alt="" />
        </div>
      {/each}
    </div>
    <div
      class="carousel-control-prev"
      role="button"
      data-slide="prev"
      on:click={cycle}>
      <span class="carousel-control-prev-icon" aria-hidden="true" />
      <span class="sr-only">Previous</span>
    </div>
    <div class="carousel-control-next" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true" />
      <span class="sr-only">Next</span>
    </div>
  </div>
</div>
