<script>
  import { location } from "svelte-spa-router";
  import PaintbrushDivider from "./PaintbrushDivider.svelte";

  let tabs = [
    { id: 0, location: "/", name: "Home" },
    { id: 1, location: "/gallery", name: "Gallery" }
    // { id: 2, location: "/contact", name: "Contact" },
    // { id: 3, location: "/test", name: "Testing" }
  ];
</script>

<style>
  .nav-item {
    --gray-text: #49494b;
    --red-text: #bf1e2e;
    color: lightgray;
    /* color: var(--gray-text); */
    font-size: 1.2rem;
  }

  .nav-item.active .nav-link {
    text-decoration: underline;
    text-decoration-color: #bf1e2e;
  }
</style>

<header
  id="MyNavbar"
  class="navbar navbar-expand-lg sticky-top navbar-dark bg-dark bd-navbar">
  <a class="navbar-brand" href="#/">
    <img
      src="logos/transparent_cropped_2.png"
      width="65"
      height="35"
      class="d-inline-block align-top"
      alt="" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon" />
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      {#each tabs as tab}
        <li class="nav-item" class:active={$location == tab.location}>
          <a class="nav-link" href="#{tab.location}">{tab.name}</a>
          {#if $location == tab.location}
            <span class="sr-only">(current)</span>
          {/if}
        </li>
      {/each}
    </ul>
  </div>
</header>
