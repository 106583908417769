module.exports = [{
        completed: false,
        title: "Natural Grocers"
    },
    {
        completed: false,
        title: "Xfinity - Castle Rock"
    },
    {
        completed: false,
        title: "Medical Building (Shell)"
    },
    {
        completed: false,
        title: "Bergen Place Condominiums (Ground-up Luxury Condos)"
    },
    {
        completed: true,
        title: "Xfinity - Parker"
    },
    {
        completed: true,
        title: "Xfinity - Denver"
    },
    {
        completed: true,
        title: "Banter Barbershop"
    },
    {
        completed: true,
        title: "Denver Premium Outlet - Whitebox"
    },
    {
        completed: true,
        title: "7 For All Mankind"
    },
    {
        completed: true,
        title: "Parachute Facility"
    },
    {
        completed: true,
        title: "City of Louisville Fire Hydrants"
    },
    {
        completed: true,
        title: "Van Heusen Retail store"
    },
    {
        completed: true,
        title: "Walmart - Parker"
    }
]