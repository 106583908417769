<script>
  import WhoWeAre from "./../components/WhoWeAre.svelte";
  import ServicesOffered from "./../components/ServicesOffered.svelte";
  import WhatSetsUsApart from "./../components/WhatSetsUsApart.svelte";

  import projects from "./../projects";
</script>

<style>
  .info-block {
    padding-top: 1rem;
    background-color: #edf2f4;
  }

  .info-block h2 {
    text-align: center;
  }

  .info-block .row * {
    text-decoration: underline;
    background-color: inherit;
  }

  .my-divider {
    border-bottom: 2px solid black;
  }
</style>

<svelte:head>
  <title>Construction Painting Professionals</title>
</svelte:head>

<div class="home-container container-fluid">
  <div class="row">
    <WhoWeAre />
  </div>

  <div class="row">
    <ServicesOffered />

    <div class="col-md-6 info-block">
      <h2>Completed Projects</h2>
      <div class="row">
        {#each projects as project}
          {#if project.completed}
            <div class="col-md-6 d-block p-2 text-center">{project.title}</div>
          {/if}
        {/each}
      </div>
    </div>

    <div class="my-divider d-md-none" />

    <div class="col-md-6 info-block">
      <h2>Current and Upcoming Projects</h2>
      <div class="row">
        {#each projects as project}
          {#if !project.completed}
            <div class="col-md-6 d-block p-2 text-center">{project.title}</div>
          {/if}
        {/each}
      </div>
    </div>

    <WhatSetsUsApart />
  </div>
</div>

<!-- <p>
    We were formed in 2018 by owners Thomas LoRusso and Connor Devine. Co-owner
    Thomas was the previous owner of the still operating 26 year old company
    LoRusso Painting. Thomas and Connor decided to form CPP and relocate to
    Denver bringing with them the knowledge, experience and dedication that was
    instilled in them while rising through the ranks in Jacksonville, Florida.
  </p> -->
